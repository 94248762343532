<template>
  <!-- 通知更多页面 -->
  <div class="noticeMore">
    <Header
      title="通知详情"
      index="首页"
      beforeTitle="通知详情"
      back="返回"
      work_bench="工作台"
    />
    <div class="not">
      <h1>
        公告通知
        <img
          src="../../assets/images/notice_opcity.png"
          alt=""
          style="width: 18px; margin-left: 5px; margin-bottom: 3px"
        />
      </h1>
      <ul v-loading="loading" element-loading-text="正在加载中···">
        <li
          @click="goDetail(item)"
          v-for="(item, index) in noticeList.data"
          :key="index"
        >
          <span>{{ item.title }}</span
          ><i>{{ item.update_time }}</i>
          <el-row class="icon-more">
            <el-col :span="12">
              <el-dropdown trigger="hover">
                <span class="el-dropdown-link">
                  <i class="el-icon-more" style="font-size: 20px"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    ><span style="color: #0f38ff" @click="noticeInfo(item)"
                      >编辑</span
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><span style="color: #ff2020" @click="noticeDel(item.id)"
                      >删除</span
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-row>
          <!-- <el-badge v-if="!item.status" :is-dot="true" class="index" /> -->
        </li>
      </ul>
      <div class="count">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="noticeParams.page"
          :page-sizes="[2, 10, 20]"
          :page-size="noticeParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="noticeList.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: true,
      noticeParams: {
        limit: 10,
        page: 1,
        order: "",
        type: "list",
      },
    };
  },
  computed: {
    ...mapState(["noticeList"]),
  },
  updated() {
    this.loading = false;
  },
  created() {
    this.$store.dispatch("getNoticeList", this.noticeParams);
    console.log(this.noticeList, "公告通知更多列表");
  },
  methods: {
    returnPage() {
      this.$router.push("/work_bench");
    },
    // 跳转详情
    goDetail(item) {
      this.$router.push({
        path: "/notice/detail_notice",
        query: { id: item.id },
      });
      console.log(item.id, "公告通知更多页面跳转详情--id");
    },
    // 删除按钮
    noticeDel(id) {
      console.log(id);
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/notice/delete", {
            params: {
              id: id,
            },
          });
          if (!id) {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          } else {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.$store.dispatch("getNoticeList", this.noticeParams);
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑按钮
    noticeInfo(item) {
      this.$router.push({ path: "/notice/add", query: { item: item } });
      console.log(item);
    },
    handleSizeChange(val) {
      this.noticeParams.limit = val;
      this.$store.dispatch("getNoticeList", this.noticeParams);
      // console.log(val, "val");
    },
    handleCurrentChange(val) {
      this.noticeParams.page = val;
      this.$store.dispatch("getNoticeList", this.noticeParams);
      // console.log(val, "当前页");
    },
  },
};
</script>

<style scoped>
.noticeMore {
  height: 100%;
}
.not {
  background: #fff;
  width: 95%;
  height: 100%;
  padding: 40px;
  margin-top: 15px;
  position: relative;
}
.not h1 {
  font-size: 18px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #000000;
}
.not h1 img {
  vertical-align: middle;
}
.not ul {
  margin-top: 14px;
}
.not ul li {
  width: 102%;
  transform: translateX(-1%);
  height: 30px;
  display: flex;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 7px 0;
  position: relative;
}
.not ul li span,
i {
  font-style: normal;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #909399;
  line-height: 30px;
}
.not ul li i {
  position: absolute;
  right: 5%;
}
.not ul li .icon-more {
  position: absolute;
  right: 1%;
  bottom: 27px;
}
.not ul li:hover {
  background: #f2f3f9;
  cursor: pointer;
}

.not .count {
  margin-top: 40px;
}
.index {
  position: absolute;
  top: 5px;
  right: 4.2%;
}
</style>
